


































































import { Vue, Component } from 'vue-property-decorator'

import { getArviointityokalut, getArviointityokaluKategoriat } from '@/api/kouluttaja'
import ElsaAccordian from '@/components/accordian/accordian.vue'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import ArviointityokaluLomakeKysymysForm from '@/forms/arviointityokalu-lomake-kysymys-form.vue'
import { Arviointityokalu, ArviointityokaluKategoria, Asiakirja } from '@/types'
import { MUU_ARVIOINTITYOKALU_ID } from '@/utils/constants'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    AsiakirjatContent,
    ArviointityokaluLomakeKysymysForm,
    ElsaAccordian
  }
})
export default class ArviointityokalutEsittely extends Vue {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arviointityokalut'),
      active: true
    }
  ]

  arviointityokalut: Arviointityokalu[] = []
  kategoriat: ArviointityokaluKategoria[] = []
  loading = false

  async mounted() {
    this.loading = true
    try {
      this.arviointityokalut = (await getArviointityokalut()).data.sort((a, b) =>
        sortByAsc(a.nimi, b.nimi)
      )
      this.kategoriat = [
        { nimi: '' },
        ...(await getArviointityokaluKategoriat()).data.sort((a, b) => sortByAsc(a.nimi, b.nimi))
      ]
    } catch {
      toastFail(this, this.$t('arviointityokalujen-kategorioiden-hakeminen-epaonnistui'))
      this.arviointityokalut = []
    }
    this.loading = false
  }

  getArviontityokalutForKategoria(id: number | null | undefined) {
    return id != null
      ? this.arviointityokalut
          .filter((item) => item.id !== MUU_ARVIOINTITYOKALU_ID)
          .filter((a) => a.kategoria?.id === id)
      : this.arviointityokalut
          .filter((item) => item.id !== MUU_ARVIOINTITYOKALU_ID)
          .filter((a) => a.kategoria == null)
  }

  produceLiite(tyokalu: Arviointityokalu): Asiakirja[] {
    return [
      {
        id: tyokalu.liite.id,
        nimi: tyokalu.liitetiedostonNimi || '',
        contentType: tyokalu.liitetiedostonTyyppi,
        data: tyokalu.liite.data,
        isDirty: false
      }
    ]
  }
}
