




























































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import { Suoritusarviointi } from '@/types'

@Component({
  components: {
    ElsaButton,
    ElsaSearchInput,
    ElsaPagination
  }
})
export default class ArvioinnitList extends Vue {
  @Prop({ required: true, default: undefined })
  arvioinnit!: null | Suoritusarviointi[]

  @Prop({ required: false, type: Boolean, default: false })
  loading!: boolean

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arvioinnit'),
      active: true
    }
  ]
  fields = [
    {
      key: 'arvioinninSaaja.nimi',
      label: this.$t('erikoistuja'),
      sortable: true
    },
    {
      key: 'tapahtumanAjankohta',
      label: this.$t('pvm'),
      sortable: true,
      thClass: 'pvm-field'
    },
    {
      key: 'tila',
      label: this.$t('tila'),
      sortable: true,
      thClass: 'tila-field'
    },
    {
      key: 'arvioitavaTapahtuma',
      label: this.$t('tapahtuma'),
      sortable: true
    },
    {
      key: 'tyoskentelyjakso.tyoskentelypaikka.nimi',
      label: this.$t('tyoskentelypaikka'),
      sortable: true
    },
    {
      key: 'teeArviointi',
      label: '',
      class: 'tee-arviointi'
    }
  ]

  hakutermi = ''
  perPage = 20
  currentPage = 1

  get tulokset() {
    return this.hakutermi
      ? this.arvioinnit?.filter(
          (item) =>
            item.arvioinninSaaja.nimi &&
            item.arvioinninSaaja.nimi.toLowerCase().includes(this.hakutermi.toLowerCase())
        ) || []
      : this.arvioinnit || []
  }

  get rows() {
    return this.tulokset?.length || 0
  }

  sortCompare(a: Suoritusarviointi, b: Suoritusarviointi, key: string): number {
    if (key == 'tila') {
      if (a.lukittu) {
        return 1
      }
      if (b.lukittu) {
        return -1
      }
      if (a.arviointiAika) {
        return 1
      }
      if (b.arviointiAika) {
        return -1
      }
      return 0
    }
    return -1
  }
}
