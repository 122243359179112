import { render, staticRenderFns } from "./arviointi.vue?vue&type=template&id=1192d4e8&scoped=true&"
import script from "./arviointi.vue?vue&type=script&lang=ts&"
export * from "./arviointi.vue?vue&type=script&lang=ts&"
import style0 from "./arviointi.vue?vue&type=style&index=0&id=1192d4e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1192d4e8",
  null
  
)

export default component.exports