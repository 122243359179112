



















































































































































import { Vue, Component, Prop } from 'vue-property-decorator'

import { FileUploadText } from '@/types'

// Maksimi tiedostokoko 20 Mt
const maxFileSize = 20 * 1024 * 1024
const maxFilesTotalSize = 100 * 1024 * 1024
const minFileSize = 100
const minPdfFileSize = 10 * 1024

@Component
export default class AsiakirjatUpload extends Vue {
  maxFilesTotalSizeExceeded = false
  filesExceedingMaxSize: File[] = []
  filesOfWrongType: File[] = []
  duplicateFilesInCurrentView: File[] = []
  duplicateFilesInOtherViews: File[] = []
  selectedFilesCount = 0
  filesBelowMinSize: File[] = []

  @Prop({ required: false })
  uploading?: boolean

  @Prop({ required: false, type: Boolean, default: true })
  isPrimaryButton!: boolean

  @Prop({ required: true, type: String })
  buttonText!: string

  @Prop({
    required: false,
    default: () => ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']
  })
  allowedFileTypes!: string[]

  @Prop({ required: false, type: String })
  wrongFileTypeErrorMessage?: string

  @Prop({ required: false, type: Boolean, default: true })
  allowMultiplesFiles!: boolean

  @Prop({ required: false, default: () => [] })
  existingFileNamesInCurrentView!: string[]

  @Prop({ required: false, default: () => [] })
  existingFileNamesInOtherViews!: string[]

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  isTextButton!: boolean

  @Prop({ required: false, type: Array, default: () => [] })
  fileUploadTexts!: FileUploadText[]

  handleFileChange(e: Event) {
    const inputElement = e.target as HTMLInputElement
    const fileArray = [...(inputElement?.files ?? [])]
    this.selectedFilesCount = fileArray.length
    // Chromea varten. Muutoin heti perään valittu sama tiedosto ei laukaise koko eventtiä.
    inputElement.value = ''
    this.maxFilesTotalSizeExceeded = this.getIsTotalFileSizeExceeded(fileArray)
    this.filesExceedingMaxSize = this.getFilesExceedingMaxSize(fileArray)
    this.filesOfWrongType = this.getFilesOfWrongType(fileArray)
    this.duplicateFilesInCurrentView = this.getduplicateFilesInCurrentView(fileArray)
    this.duplicateFilesInOtherViews = this.getduplicateFilesInOtherViews(fileArray)
    this.filesBelowMinSize = this.getFilesBelowMinSize(fileArray)

    if (!this.hasErrors) {
      this.selectedFilesCount = 0
      this.$emit('selectedFiles', fileArray)
    }
  }

  getIsTotalFileSizeExceeded(files: File[]): boolean {
    return files.reduce((sum: number, current: File) => sum + current.size, 0) > maxFilesTotalSize
  }

  getFilesExceedingMaxSize(files: File[]): File[] {
    return files.filter((file) => file.size > maxFileSize)
  }

  getFilesOfWrongType(files: File[]): File[] {
    return files.filter((file) => !this.allowedFileTypes.includes(file.type))
  }

  getduplicateFilesInCurrentView(files: File[]): File[] {
    return files.filter((file) => this.existingFileNamesInCurrentView?.includes(file.name))
  }

  getduplicateFilesInOtherViews(files: File[]): File[] {
    return files.filter((file) => this.existingFileNamesInOtherViews?.includes(file.name))
  }

  // Yritetään suodattaa tyhjät tiedostot pois (PDF/10kt, muut 100b)
  getFilesBelowMinSize(files: File[]): File[] {
    return files.filter(
      (file) => file.size < (file.type === 'application/pdf' ? minPdfFileSize : minFileSize)
    )
  }

  onDismissAlert() {
    this.maxFilesTotalSizeExceeded = false
    this.filesExceedingMaxSize = []
    this.filesOfWrongType = []
    this.duplicateFilesInCurrentView = []
    this.duplicateFilesInOtherViews = []
    this.filesBelowMinSize = []
  }

  get uid() {
    return `elsa-asiakirjat-upload-${(this as any)._uid}`
  }

  get hasErrors() {
    return (
      this.maxFilesTotalSizeExceeded ||
      this.filesExceedingMaxSize.length > 0 ||
      this.filesOfWrongType.length > 0 ||
      this.duplicateFilesInCurrentView.length > 0 ||
      this.duplicateFilesInOtherViews.length > 0 ||
      this.filesBelowMinSize.length > 0
    )
  }
}
