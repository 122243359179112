















































































































































import { Component, Mixins } from 'vue-property-decorator'
import { Validation, validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { deleteArviointityokalu, getArviointityokalu } from '@/api/tekninen-paakayttaja'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ArviointityokaluLomakeKysymysForm from '@/forms/arviointityokalu-lomake-kysymys-form.vue'
import { Arviointityokalu, Asiakirja } from '@/types'
import { confirmExit } from '@/utils/confirm'
import { ArviointityokaluTila } from '@/utils/constants'
import { mapFile } from '@/utils/fileMapper'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    ArviointityokaluLomakeKysymysForm,
    AsiakirjatContent,
    ElsaButton,
    ElsaConfirmationModal,
    ElsaFormGroup,
    ElsaFormMultiselect
  },
  validations: {
    form: {
      nimi: {
        required
      }
    }
  }
})
export default class ArviointityokaluView extends Mixins(validationMixin) {
  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arviointityokalut'),
      active: false
    },
    {
      text: this.$t('arviointityokalu'),
      active: true
    }
  ]

  form: Arviointityokalu = {
    id: -1,
    nimi: null,
    ohjeteksti: null,
    kategoria: null,
    kysymykset: [],
    liite: null,
    tila: ArviointityokaluTila.LUONNOS
  }

  asiakirjat: Asiakirja[] = []
  skipRouteExitConfirm = true
  deleting = false
  loading = false

  async mounted() {
    this.fetchArviointityokalu()
  }

  async fetchArviointityokalu() {
    try {
      this.form = (
        await getArviointityokalu(Number(this.$route?.params?.arviointityokaluId))
      ).data
      if (this.form.liite) {
        const data = Uint8Array.from(atob(this.form.liite.data), (c) => c.charCodeAt(0))
        this.asiakirjat.push(
          mapFile(
            new File([data], this.form.liitetiedostonNimi || '', {
              type: this.form.liitetiedostonTyyppi || ''
            })
          )
        )
      }
    } catch (err) {
      toastFail(this, this.$t('arviointityokalun-hakeminen-epaonnistui'))
      this.$router.replace({ name: 'arviointityokalut' })
      this.loading = false
    }
  }

  async onCancel() {
    if (this.skipRouteExitConfirm || (await confirmExit(this))) {
      this.$v.form.$reset()
      this.skipRouteExitConfirm = true
      this.$emit('skipRouteExitConfirm', true)
    }
  }

  showDeleteConfirm() {
    this.$bvModal.show('confirm-dialog')
  }

  onCancelConfirm() {
    this.$emit('skipRouteExitConfirm', true)
  }

  async onArviointityokaluDelete() {
    try {
      if (this.form && this.form.id) {
        await deleteArviointityokalu(this.form.id)
        toastSuccess(this, this.$t('arviointityokalu-poistettu'))
        this.skipRouteExitConfirm = true
        this.$router.replace({ name: 'arviointityokalut' })
      }
    } catch {
      // todo error
      this.$router.replace({ name: 'arviointityokalut' })
    }
  }

  validateConfirm() {
    const { $dirty, $error } = this.$v.reassignedKouluttaja as Validation
    return $dirty ? ($error ? false : null) : null
  }
}
