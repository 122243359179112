









































































import { Component, Vue } from 'vue-property-decorator'

import { getArviointityokalut, getArviointityokalutKategoriat } from '@/api/tekninen-paakayttaja'
import ElsaButton from '@/components/button/button.vue'
import Pagination from '@/components/pagination/pagination.vue'
import SearchInput from '@/components/search-input/search-input.vue'
import { Arviointityokalu, ArviointityokaluKategoria } from '@/types'
import { MUU_ARVIOINTITYOKALU_ID } from '@/utils/constants'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    SearchInput,
    Pagination
  }
})
export default class Arviointityokalut extends Vue {
  arviointityokaluKategoriat: ArviointityokaluKategoria[] = []
  arviointityokalut: Arviointityokalu[] = []

  loading = true

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('arviointityokalut'),
      active: true
    }
  ]

  fields = [
    {
      key: 'nimi',
      label: this.$t('nimi'),
      class: 'nimi',
      sortable: false
    },
    {
      key: 'tila',
      label: this.$t('tila'),
      class: 'tila',
      sortable: false
    }
  ]
  perPage = 20
  currentPage = 1

  async mounted() {
    this.loading = true
    try {
      this.arviointityokaluKategoriat = (await getArviointityokalutKategoriat()).data.sort(
        (a, b) => sortByAsc(a.nimi, b.nimi)
      )
      this.arviointityokalut = (await getArviointityokalut()).data
        .sort((a, b) => sortByAsc(a.nimi, b.nimi))
        .filter((at) => at.id !== MUU_ARVIOINTITYOKALU_ID)
    } catch {
      toastFail(this, this.$t('arviointityokalujen-kategorioiden-hakeminen-epaonnistui'))
      this.arviointityokaluKategoriat = []
      this.arviointityokalut = []
    }
    this.loading = false
  }

  get flatList() {
    let list = []
    list.push(
      ...this.arviointityokalut
        .filter((tool) => tool.kategoria === null)
        .map((tool) => ({ ...tool, isCategory: false, arviointityokaluId: tool.id }))
    )
    this.arviointityokaluKategoriat.forEach((category) => {
      list.push({ nimi: category.nimi, isCategory: true, kategoriaId: category.id })
      list.push(
        ...this.arviointityokalut
          .filter((tool) => tool.kategoria?.id === category.id)
          .map((tool) => ({ ...tool, isCategory: false, arviointityokaluId: tool.id }))
      )
    })
    return list
  }
}
