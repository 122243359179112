













import { Vue, Component, Prop } from 'vue-property-decorator'

import ArviointityokalutArvioijaForm from '@/forms/arviointityokalut-arvioija-form.vue'
import { Arviointityokalu, SuoritusarviointiArviointityokaluVastaus } from '@/types'

@Component({
  components: { ArviointityokalutArvioijaForm }
})
export default class TyokertymalaskuriModalContent extends Vue {
  @Prop({ required: true, type: Array, default: () => [] })
  valitutArviointityokalut!: Arviointityokalu[]

  @Prop({ required: true, type: Array, default: () => [] })
  arviointityokaluVastaukset!: SuoritusarviointiArviointityokaluVastaus[]

  loading = false

  async mounted() {
    this.loading = false
  }

  onSubmit(formData: any, params: any) {
    this.$emit('submit', formData, params)
  }

  onDelete(id: number) {
    this.$emit('delete', id)
  }

  onCancel() {
    this.$emit('closeModal')
  }

  skipRouteExitConfirm(value: boolean) {
    this.$emit('skipRouteExitConfirm', value)
  }
}
