































































































































































































































































































































































































































































































































































































import axios from 'axios'
import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { Validation, validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import ElsaAccordian from '@/components/accordian/accordian.vue'
import ElsaArviointiasteikonTasoTooltipContent from '@/components/arviointiasteikon-taso/arviointiasteikon-taso-tooltip.vue'
import ElsaArviointiasteikonTaso from '@/components/arviointiasteikon-taso/arviointiasteikon-taso.vue'
import ArviointityokaluKysymysLomakeVastauksilla from '@/components/arviointityokalut/arviointityokalu-kysymys-lomake-vastauksilla.vue'
import ArviointityokalutModal from '@/components/arviointityokalut/arviointityokalut-modal.vue'
import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaBadge from '@/components/badge/badge.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import TyokertymalaskuriModal from '@/components/tyokertymalaskuri/tyokertymalaskuri-modal.vue'
import UserAvatar from '@/components/user-avatar/user-avatar.vue'
import ElsaVaativuustasoTooltipContent from '@/components/vaativuustaso/vaativuustaso-tooltip-content.vue'
import ElsaVaativuustaso from '@/components/vaativuustaso/vaativuustaso.vue'
import ArviointityokaluLomakeKysymysForm from '@/forms/arviointityokalu-lomake-kysymys-form.vue'
import store from '@/store'
import {
  ArviointiasteikonTaso,
  Arviointityokalu,
  Asiakirja,
  FileUploadText,
  Suoritusarviointi,
  SuoritusarviointiArviointityokaluVastaus,
  SuoritusarviointiForm,
  Vaativuustaso
} from '@/types'
import { resolveRolePath } from '@/utils/apiRolePathResolver'
import {
  ArvioinninPerustuminen,
  ArviointiasteikkoTyyppi,
  MUU_ARVIOINTITYOKALU_ID,
  vaativuustasot
} from '@/utils/constants'
import { mapFile } from '@/utils/fileMapper'
import { sortByAsc } from '@/utils/sort'

@Component({
  components: {
    ElsaConfirmationModal,
    ElsaAccordian,
    ArviointityokaluKysymysLomakeVastauksilla,
    ArviointityokaluLomakeKysymysForm,
    TyokertymalaskuriModal,
    ArviointityokalutModal,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    UserAvatar,
    ElsaArviointiasteikonTaso,
    ElsaBadge,
    ElsaPopover,
    ElsaButton,
    ElsaVaativuustaso,
    AsiakirjatUpload,
    AsiakirjatContent,
    ElsaVaativuustasoTooltipContent,
    ElsaArviointiasteikonTasoTooltipContent
  },
  validations: {
    form: {
      arvioitavatKokonaisuudet: {
        $each: {
          arviointiasteikonTaso: {
            required
          }
        }
      },
      sanallinenArviointi: {
        required
      },
      arviointiPerustuu: {
        required: requiredIf((value) => {
          return value.perustuuMuuhun === true
        })
      },
      muuPeruste: {
        required: requiredIf((value) => {
          return value.arviointiPerustuu === ArvioinninPerustuminen.MUU
        })
      }
    }
  }
})
export default class ArviointiForm extends Mixins(validationMixin) {
  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: true, type: Object })
  value!: Suoritusarviointi

  @Prop({ required: false, default: false })
  itsearviointi!: boolean

  // Joko arviointi tai itsearviointi
  form: SuoritusarviointiForm = {
    vaativuustaso: null,
    arvioitavatKokonaisuudet: null,
    sanallinenArviointi: null,
    arviointityokalut: [],
    arviointityokaluVastaukset: [],
    arviointiPerustuu: null,
    muuPeruste: null,
    perustuuMuuhun: false,
    keskenerainen: false
  }
  vaativuustasot = vaativuustasot
  arviointiasteikonTasot: ArviointiasteikonTaso[] = []
  arviointityokalut: Arviointityokalu[] = []
  addedFiles: File[] = []
  newAsiakirjatMapped: Asiakirja[] = []
  deletedAsiakirjat: Asiakirja[] = []
  params = {
    saving: false
  }
  arviointiperusteet = [
    {
      text: this.$t('arviointi-perustuu-kirjallinen'),
      value: ArvioinninPerustuminen.KIRJALLINEN
    },
    {
      text: this.$t('arviointi-perustuu-etayhteys'),
      value: ArvioinninPerustuminen.ETA
    },
    {
      text: this.$t('arviointi-perustuu-muu'),
      value: ArvioinninPerustuminen.MUU
    }
  ]
  fileUploadTexts: FileUploadText[] = [
    {
      text: this.$t('voit-myos-lisata-valmiin-arvioinnin') as string,
      isLink: false,
      link: '',
      linkType: null
    },
    {
      text: this.$t('liitteena') as string,
      isLink: true,
      link: '',
      linkType: 'upload'
    },
    {
      text: this.$t('erillisessa-nakymassa-voit') as string,
      isLink: false,
      link: '',
      linkType: null
    },
    {
      text: this.$t('tutustua-arviointityokaluihin') as string,
      isLink: true,
      link: 'arviointityokalu-esittely',
      linkType: 'navigation'
    }
  ]
  isArviointityokalutModalOpen = false

  async mounted() {
    this.arviointiasteikonTasot = this.value.arviointiasteikko.tasot
    if (this.itsearviointi) {
      this.form = {
        vaativuustaso: vaativuustasot.find(
          (taso) => taso.arvo === this.value.itsearviointiVaativuustaso
        ),
        arvioitavatKokonaisuudet: this.value.arvioitavatKokonaisuudet.map((k) => {
          return {
            ...k,
            arviointiasteikonTaso: this.arviointiasteikonTasot.find(
              (asteikonTaso) => asteikonTaso.taso === k.itsearviointiArviointiasteikonTaso
            )
          }
        }),
        sanallinenArviointi: this.value.sanallinenItsearviointi,
        arviointityokaluVastaukset: this.value.arviointityokaluVastaukset,
        keskenerainen: false
      }
    } else {
      this.form = {
        ...this.value,
        vaativuustaso: vaativuustasot.find((taso) => taso.arvo === this.value.vaativuustaso),
        arvioitavatKokonaisuudet: this.value.arvioitavatKokonaisuudet.map((k) => {
          return {
            ...k,
            arviointiasteikonTaso: this.arviointiasteikonTasot.find(
              (asteikonTaso) => asteikonTaso.taso === k.arviointiasteikonTaso
            )
          }
        }),
        sanallinenArviointi: this.value.sanallinenArviointi,
        arviointityokalut: this.value.arviointityokalut,
        arviointiPerustuu:
          this.value.arviointiPerustuu === ArvioinninPerustuminen.LASNA
            ? null
            : this.value.arviointiPerustuu,
        muuPeruste: this.value.muuPeruste,
        perustuuMuuhun:
          this.value.arviointiPerustuu !== null &&
          this.value.arviointiPerustuu !== ArvioinninPerustuminen.LASNA,
        arviointityokaluVastaukset: this.value.arviointityokaluVastaukset,
        keskenerainen: false
      }
      this.arviointityokalut = (
        (await axios.get(`/arviointityokalut`)).data as Arviointityokalu[]
      )?.sort((a, b) => {
        if (a.nimi === 'Muu') {
          return 1
        } else {
          return sortByAsc(a.nimi, b.nimi)
        }
      })
    }
  }

  get account() {
    return store.getters['auth/account']
  }

  get muuValittu() {
    return this.form.arviointiPerustuu === ArvioinninPerustuminen.MUU
  }

  get arviointiAsteikonNimi() {
    return this.value.arviointiasteikko.nimi === ArviointiasteikkoTyyppi.EPA
      ? this.$t('luottamuksen-taso')
      : this.$t('etappi')
  }

  get asiakirjatTableItems() {
    return (
      this.itsearviointi ? this.value.itsearviointiAsiakirjat : this.value.arviointiAsiakirjat
    )
      .filter((a) => !this.deletedAsiakirjat.includes(a))
      .concat(this.newAsiakirjatMapped)
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateArvioitavaKokonaisuusState(index: number) {
    const { $dirty, $error } = this.$v.form?.arvioitavatKokonaisuudet?.$each[index]
      ?.arviointiasteikonTaso as Validation
    return $dirty ? ($error ? false : null) : null
  }

  get pakollisetVastauksetValid() {
    if (!this.form.arviointityokalut) return true
    return this.form.arviointityokalut.every((tool) =>
      tool.kysymykset.every((kysymys) => {
        if (!kysymys.pakollinen) return true
        if (!this.form.arviointityokaluVastaukset) return false
        const vastaus = this.form.arviointityokaluVastaukset.find(
          (v) => v.arviointityokaluKysymysId === kysymys.id
        )
        return vastaus && (vastaus.tekstiVastaus || vastaus.valittuVaihtoehtoId)
      })
    )
  }

  onArviointiFileAdded(files: File[]) {
    const file = files[0]
    this.newAsiakirjatMapped.push(mapFile(file))
    this.addedFiles.push(file)
  }

  onArviointiFileDeleted(asiakirja: Asiakirja) {
    if (asiakirja.id) {
      this.deletedAsiakirjat = [asiakirja, ...this.deletedAsiakirjat]
    } else {
      this.addedFiles = this.addedFiles?.filter((file) => file.name !== asiakirja.nimi)
      this.newAsiakirjatMapped = this.newAsiakirjatMapped?.filter(
        (a) => a.nimi !== asiakirja.nimi
      )
    }
  }

  onSubmit(validate = true) {
    if (validate) {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
    }
    if (this.itsearviointi) {
      const submitData = {
        suoritusarviointi: {
          ...this.value,
          arvioitavatKokonaisuudet: this.form.arvioitavatKokonaisuudet?.map((k) => {
            return {
              ...k,
              itsearviointiArviointiasteikonTaso: (
                k.arviointiasteikonTaso as ArviointiasteikonTaso
              ).taso,
              arviointiasteikonTaso: null
            }
          }),
          itsearviointiVaativuustaso: this.form.vaativuustaso?.arvo,
          sanallinenItsearviointi: this.form.sanallinenArviointi,
          arviointiasteikko: null,
          arviointiAsiakirjat: null,
          itsearviointiAsiakirjat: null,
          keskenerainen: this.form.keskenerainen
        },
        addedFiles: this.addedFiles,
        deletedAsiakirjaIds: this.deletedAsiakirjat.map((asiakirja) => asiakirja.id)
      }
      this.$emit('submit', submitData, this.params)
    } else {
      const submitData = {
        suoritusarviointi: {
          ...this.value,
          arvioitavatKokonaisuudet: this.form.arvioitavatKokonaisuudet?.map((k) => {
            return {
              ...k,
              arviointiasteikonTaso: (k.arviointiasteikonTaso as ArviointiasteikonTaso).taso,
              itsearviointiArviointiasteikonTaso: null
            }
          }),
          vaativuustaso: this.form.vaativuustaso?.arvo,
          sanallinenArviointi: this.form.sanallinenArviointi,
          arviointityokalut: this.form.arviointityokalut,
          arviointityokaluVastaukset: this.form.arviointityokaluVastaukset,
          arviointiPerustuu: this.form.perustuuMuuhun
            ? this.form.arviointiPerustuu
            : ArvioinninPerustuminen.LASNA,
          muuPeruste: this.muuValittu ? this.form.muuPeruste : null,
          arviointiAsiakirjat: null,
          itsearviointiAsiakirjat: null,
          arviointiasteikko: null,
          keskenerainen: this.form.keskenerainen
        },
        addedFiles: this.addedFiles,
        deletedAsiakirjaIds: this.deletedAsiakirjat.map((asiakirja) => asiakirja.id)
      }
      this.$emit('submit', submitData, this.params)
    }
  }

  get asiakirjaDataEndpointUrl() {
    return this.value.id
      ? `${resolveRolePath()}/suoritusarvioinnit/${this.value.id}/arviointi-liite`
      : ''
  }

  get linkki() {
    return `<a
              href="https://www.laaketieteelliset.fi/ammatillinen-jatkokoulutus/elsa"
              target="_blank"
              rel="noopener noreferrer"
            >${this.$t('arviointi-liitetiedostot-kuvaus-linkki')}</a>`
  }

  arviointiasteikonTasoLabel(value: ArviointiasteikonTaso) {
    return `${value.taso} ${this.$t('arviointiasteikon-taso-' + value.nimi)}`
  }

  vaativuustasoLabel(value: Vaativuustaso) {
    return `${value.arvo} ${this.$t(value.nimi)}`
  }

  muuPerustePituus() {
    return this.form.muuPeruste != null ? this.form.muuPeruste.length : 0
  }

  get formattedArviointityokalut() {
    const grouped = this.arviointityokalut
      .filter((item) => item.id !== MUU_ARVIOINTITYOKALU_ID)
      .reduce<Record<string, { kategoria: string; nimi: Arviointityokalu[] }>>(
        (acc, arviointityokalu) => {
          const categoryName =
            arviointityokalu.kategoria?.nimi || (this.$t('ei-kategoriaa') as string)
          if (!acc[categoryName]) {
            acc[categoryName] = { kategoria: categoryName, nimi: [] }
          }
          acc[categoryName].nimi.push(arviointityokalu)
          return acc
        },
        {}
      )

    const muuArviointityokalu = this.arviointityokalut.find((item) => item.id === 9)
    if (muuArviointityokalu) {
      const muuCategoryName = this.$t('muu') as string
      if (!grouped[muuCategoryName]) {
        grouped[muuCategoryName] = { kategoria: muuCategoryName, nimi: [] }
      }
      grouped[muuCategoryName].nimi.push(muuArviointityokalu)
    }

    return Object.values(grouped)
  }

  get arviointityokalujaEiValittuna() {
    if (this.form.arviointityokalut && this.form.arviointityokalut.length === 0) return true
    return (
      this.form.arviointityokalut &&
      this.form.arviointityokalut.length === 1 &&
      this.form.arviointityokalut[0].id === MUU_ARVIOINTITYOKALU_ID
    )
  }

  arviointityokaluLabel(arviointityokalu: Arviointityokalu) {
    return arviointityokalu.nimi || ''
  }

  openArviointityokalutModal() {
    this.isArviointityokalutModalOpen = true
  }

  async lisaaArviointityokaluVastaukset(formData: SuoritusarviointiArviointityokaluVastaus[]) {
    this.form.arviointityokaluVastaukset = formData
    this.isArviointityokalutModalOpen = false
  }

  getKysymyksenVastaus(kysymysId: number | undefined, arviointityokaluId: number | undefined) {
    if (this.form.arviointityokaluVastaukset === undefined) {
      return null
    }
    const vastaus = this.form.arviointityokaluVastaukset.find(
      (v) =>
        v.arviointityokaluKysymysId === kysymysId && v.arviointityokaluId === arviointityokaluId
    )
    return vastaus || null
  }

  saveUnfinishedAndExit() {
    this.form.keskenerainen = true
    this.onSubmit(false)
  }

  get listattavatArviointityokalut() {
    if (!this.form.arviointityokalut) return []
    return this.form.arviointityokalut.filter((at) => at.id !== MUU_ARVIOINTITYOKALU_ID)
  }
}
