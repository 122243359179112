




























































import Component from 'vue-class-component'
import { Vue, Prop } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import { ArviointityokaluKysymys, SuoritusarviointiArviointityokaluVastaus } from '@/types'
import { ArviointityokaluKysymysTyyppi } from '@/utils/constants'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    ElsaFormDatepicker,
    ElsaPopover
  },
  validations: {}
})
export default class ArviointityokaluLomakeKysymysForm extends Vue {
  @Prop({ required: true, type: Number, default: 0 })
  arviointityokaluId: number | undefined

  @Prop({ type: Object, required: true })
  kysymys!: ArviointityokaluKysymys

  @Prop({ type: Object, default: null })
  vastaus!: SuoritusarviointiArviointityokaluVastaus | null

  @Prop({ type: Boolean, default: false })
  childDataReceived!: boolean

  @Prop({ type: Boolean, default: false })
  answerMode!: boolean

  @Prop({ required: true, type: Number })
  index?: number

  selectedAnswer: string | number | null = null

  mounted() {
    if (this.vastaus) {
      this.selectedAnswer = this.vastaus.valittuVaihtoehtoId || this.vastaus.tekstiVastaus || null
    }
  }

  get isAnswerValid() {
    if (!this.kysymys.pakollinen) return null
    return this.selectedAnswer !== null && this.selectedAnswer !== '' ? null : false
  }

  get arviointityokaluKysymysTyyppit() {
    return ArviointityokaluKysymysTyyppi
  }

  updateAnswer(value: string | number | undefined) {
    this.$emit('update-answer', {
      arviointityokaluId: this.arviointityokaluId,
      arviointityokaluKysymysId: this.kysymys.id,
      tekstiVastaus: typeof value === 'string' ? value : null,
      valittuVaihtoehtoId: typeof value === 'number' ? value : null
    })
  }
}
