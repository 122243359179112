


































































import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ArviointityokaluLomakeKysymysForm from '@/forms/arviointityokalu-lomake-kysymys-form.vue'
import { Arviointityokalu, SuoritusarviointiArviointityokaluVastaus } from '@/types'

@Component({
  components: {
    ArviointityokaluLomakeKysymysForm,
    ElsaButton,
    ElsaFormError
  },
  validations: {}
})
export default class ArviointityokalutArvioijaForm extends Vue {
  @Prop({ required: true, type: Array, default: () => [] })
  valitutArviointityokalut!: Arviointityokalu[]

  @Prop({ required: true, type: Array, default: () => [] })
  arviointityokaluVastaukset!: SuoritusarviointiArviointityokaluVastaus[]

  collapsedIndex: number | null = null
  params = {
    saving: false,
    deleting: false
  }
  editing = false

  toggleCollapse(index: number) {
    this.collapsedIndex = this.collapsedIndex === index ? null : index
  }

  async onSubmit() {
    const submitData: SuoritusarviointiArviointityokaluVastaus[] = [
      ...this.arviointityokaluVastaukset
    ]
    this.$emit('skipRouteExitConfirm', true)
    this.$emit('submit', submitData, this.params)
  }

  onCancel() {
    this.$emit('skipRouteExitConfirm', true)
    this.$emit('cancel')
  }

  updateVastaus(vastaus: SuoritusarviointiArviointityokaluVastaus) {
    const index = this.arviointityokaluVastaukset.findIndex(
      (v) => v.arviointityokaluKysymysId === vastaus.arviointityokaluKysymysId
    )
    const updatedVastaus = {
      ...vastaus,
      arviointityokaluId: vastaus.arviointityokaluId
    }
    if (index !== -1) {
      this.$set(this.arviointityokaluVastaukset, index, updatedVastaus)
    } else {
      this.arviointityokaluVastaukset.push(updatedVastaus)
    }
  }

  getKysymyksenVastaus(kysymysId: number | undefined, arviointityokaluId: number | undefined) {
    const vastaus = this.arviointityokaluVastaukset.find(
      (v) =>
        v.arviointityokaluKysymysId === kysymysId && v.arviointityokaluId === arviointityokaluId
    )
    return vastaus || null
  }
}
