





































import Component from 'vue-class-component'
import { Vue, Prop } from 'vue-property-decorator'

import { ArviointityokaluKysymys, SuoritusarviointiArviointityokaluVastaus } from '@/types'
import { ArviointityokaluKysymysTyyppi } from '@/utils/constants'

@Component
export default class ArviointityokaluKysymysVastausNaytto extends Vue {
  @Prop({ type: Object, required: true })
  kysymys!: ArviointityokaluKysymys

  @Prop({ type: Object, default: null })
  vastaus!: SuoritusarviointiArviointityokaluVastaus | null

  selectedAnswer: string | number | null = null

  mounted() {
    if (this.vastaus) {
      this.selectedAnswer = this.vastaus.valittuVaihtoehtoId || this.vastaus.tekstiVastaus || null
    }
  }

  get arviointityokaluKysymysTyyppit() {
    return ArviointityokaluKysymysTyyppi
  }
}
